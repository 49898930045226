import React from "react"
import Accueil from "../components/Accueil/Accueil.jsx"
import Seo from "../components/SEO"

export default function Home() {
  return (
    <>
      <Seo title="Accueil" />
      <Accueil />
    </>
  )
}
