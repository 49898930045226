import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import styled from "styled-components"
import VerticalSpacer from "./VerticalSpacer"

const StyledCard = styled.div`
  svg {
    height: 35px;
    opacity: 0.7;
    color: #544;
  }
  .cardTitle {
    text-align: center;
    color: #544;
    font-size: 20px;
    font-family: Avenir, system-ui;
  }
`

/* CARD COMPONENT */
export default function Card({ domain }) {
  const { title, subtitle, icon } = domain
  return (
    /* 1 CONTAINER */
    <StyledCard className="shadowed p-5 rounded">
      {/* 1.1 SVG ILLUSTRATION */}
      <VerticalSpacer height={"10px"} />
      <div className="align-center">
        <FontAwesomeIcon style={{ margin: "auto" }} icon={icon} />
      </div>
      <VerticalSpacer />
      {/* 1.2 TITLE */}
      <h2 className="cardTitle smallHeading">{title}</h2>
      <VerticalSpacer height={"10px"} />
      {/* 1.1 TEXT */}
      <p className="tertiary align-center">{subtitle}</p>
    </StyledCard>
  )
}
