import React from "react"
import styled from "styled-components"
import VerticalSpacer from "../VerticalSpacer"
import { phoneSVG, mailSVG } from "../../assets/svg/SVGStorage"

const StyledContact = styled.div`
  text-align: center;
  .contactButton {
    font-size: 14px;
    white-space: nowrap;
    gap: 0.5em;
    display: inline-flex;
    width: 100%;
    justify-content: center;
  }
  svg {
    height: 18px;
  }
`

export default function Contact() {
  return (
    <StyledContact>
      <div className="shadowed rounded padded">
        <h2 className="smallHeading tamedBlack">Horaires et Coordonnées</h2>
        <VerticalSpacer height="8px" />
        <div className="gray3 text-sm mb-1">Lundi au Vendredi</div>

        <div className="gray3 text-sm mb-1">8h30-12h30 • 13h30-18h30</div>
        <VerticalSpacer height="8px" />

        <button
          className="primary-button outline-button contactButton font-semibold
		   py-1.5 px-3 
				 rounded-md avenir text-sm"
        >
          {phoneSVG} <div>04 94 51 16 16</div>
        </button>
        <VerticalSpacer height="8px" />
        <button
          className="primary-button contactButton font-semibold
		   py-1.5 px-3 
				 rounded-md avenir text-sm"
        >
          {mailSVG} <div>cabinet@lexcoavocats.com</div>
        </button>
        <VerticalSpacer height="16px" />
        <div className="gray3 text-sm mb-1">
          64 Via Nova • Pôle d'Excellence Jean Louis • 83600 FREJUS
        </div>
        <div className="gray3 text-sm mb-1">5 Rue Barla • 06000 NICE</div>

        <VerticalSpacer height="8px" />
      </div>
    </StyledContact>
  )
}
