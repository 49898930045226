import Card from "../Card"
import CardContainer from "../CardContainer"
import {
  faBriefcase,
  faChartSimple,
  faPeoplePants,
  faGlobe,
  faHouse,
  faLandmark,
} from "@fortawesome/pro-thin-svg-icons"
import React from "react"

const domains = {
  affaires: {
    title: "Droit des Affaires",
    subtitle:
      "Sociétés commerciales, Fonds de commerce, Bail commercial, Contrats d'affaires, Procédures collectives",
    icon: faChartSimple,
  },

  famille: {
    title: "Droit de la Famille",
    subtitle:
      "Divorce par consentement mutuel, Divorce judiciaire, Droit des personnes, Régimes matrimoniaux",
    icon: faPeoplePants,
  },
  immobilier: {
    title: "Droit Immobilier",
    subtitle:
      "Société Civile Immobilière (SCI), Nu-Propriété, Usufruit, Droit de la Construction, Troubles de voisinnage",
    icon: faHouse,
  },
  civil: {
    title: "Droit Civil",
    subtitle:
      "Responsabilité civile, Dommages, Responsabilité contractuelle, Successions, Droit du patrimoine",
    icon: faLandmark,
  },
  travail: {
    title: "Droit du Travail",
    subtitle:
      "Contrat de travail, Règlement intérieur, Formalités et entretiens, Licenciement et contestation, Prud'hommes",
    icon: faBriefcase,
  },
  international: {
    title: "Droit International",
    subtitle:
      "Divorce international judiciaire, Droit international privé, Recouvrement international",
    icon: faGlobe,
  },
}

export default function MarketingSectionDomains() {
  return (
    <CardContainer>
      <Card domain={domains.affaires} />
      <Card domain={domains.travail} />
      <Card domain={domains.civil} />
      <Card domain={domains.immobilier} />
      <Card domain={domains.famille} />
      <Card domain={domains.international} />
    </CardContainer>
  )
}
