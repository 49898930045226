import React from "react"
import styled from "styled-components"
import Panorama from "./Panorama"
import VerticalSpacer from "../VerticalSpacer"
/* import { Link } from "gatsby"
 */ import Domains from "./Domains"
// import LatestNews from "./LatestNews"
import ContactUs from "./ContactUs"
import Lawyers from "./Lawyers"
import Hero from "./Hero"
const StyledAccueil = styled.div`
  /* border-bottom:3px solid hsl(354, 87%, 75%);
  color:inherit; */

  margin-bottom: 2em;
`
export default function Accueil() {
  return (
    <StyledAccueil>
      <Panorama />

      <div className="container">
        {/*  */}
        {/* 1. Hero */}
        {/*  */}

        <Hero />
        <VerticalSpacer height="32px" />

        {/*  */}
        {/* 2. Domaines */}
        {/*  */}

        <h2 className="avenir tamedBlack fancyLink limitedWidth ">
          <span id="services"></span>
          Domaines d'activité
        </h2>
        <VerticalSpacer height="20px" />
        <Domains />
        <VerticalSpacer height="32px" />

        {/*  */}
        {/*  3. Lawyers*/}
        {/*  */}

        <h2 className="avenir tamedBlack fancyLink limitedWidth">
          <span id="lawyers"></span>
          Avocats
        </h2>
        <VerticalSpacer height="20px" />
        <Lawyers />
        <VerticalSpacer height="32px" />

        {/*  */}
        {/* 4. News */}
        {/*  */}

        {/*    <h2 className=" avenir tamedBlack fancyLink limitedWidth">
          <span id="news"></span>
          Dernière Publication
        </h2>
        <VerticalSpacer height="20px" />
        <LatestNews />
        <VerticalSpacer height="32px" /> */}

        {/*  */}
        {/* 5. Contact */}
        {/*  */}

        {
          <h2 className="avenir tamedBlack fancyLink limitedWidth ">
            <span id="contact"></span>
            Contact
          </h2>
        }

        <VerticalSpacer height="20px" />
        <ContactUs />

        <VerticalSpacer height="16px" />

        {/*  */}
        {/*  */}
        {/*  */}

        {/*   <Link className="fancyLink bold" to="StyleReference">
          Style Reference
        </Link> */}
        {/* <VerticalSpacer height="32px" /> */}

        {/*   <Link className="fancyLink bold" to="Honoraires">
          Honoraires
        </Link> */}
      </div>
    </StyledAccueil>
  )
}
