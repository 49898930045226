import React from "react"
import VerticalSpacer from "../VerticalSpacer"
import google_ratings from "../../images/google_ratings.png"
import { phoneSVG, linkedInSVG } from "../../assets/svg/SVGStorage"
import styled from "styled-components"

const StyledHero = styled.div`
  button {
    display: flex;
    gap: 0.5rem;
    align-items: center;
  }
  #phoneSVG {
    width: 18px;
    fill: currentColor;
  }
  .no-wrap {
    white-space: nowrap;
  }
  .fancyLink {
    cursor: pointer;
  }
  .fancyLink::after {
    display: none;
  }
`

export default function Hero() {
  return (
    <StyledHero>
      <VerticalSpacer height="30px" />
      <div className="flex gap-y-4 flex-col md:flex-row ">
        <div>
          <h1 className="bigHeading red">
            Cabinet d'avocats en France et à l'International
          </h1>
          <VerticalSpacer height="8px" />
          <p>
            Lex&Co Avocats est un cabinet d'avocats fondé en 2005 par{" "}
            <a className="fancyLink bold" href="#lawyers">
              {" "}
              Me Sophie Morreel Weber
            </a>
            , rejointe en 2017 par{" "}
            <a className="fancyLink bold" href="#lawyers">
              Me Virginie Parisse
            </a>
            {/* en 2021 par{" "}
            <a className="fancyLink bold" href="#lawyers">
              Me Julie Vigneron
            </a>{" "} */}
            , et en 2022 par{" "}
            <a className="fancyLink bold" href="#lawyers">
              Julie Lepretre
            </a>
            , juriste.
          </p>
          <p>
            {" "}
            Basé à Fréjus et à Nice, le cabinet opère dans toute le France pour
            les français résidents et non résidents et pour les étrangers
            recherchant un cabinet français habitué aux problématiques
            internationales. Pour la Chine, notre cabinet travaille en relation
            avec le cabinet Coster Associates basé à Shanghai.
          </p>

          <p></p>
        </div>
        <div
          style={{ gap: "8px" }}
          className="mx-10 flex flex-col items-center justify-center"
        >
          <a
            target="_blank"
            href="https://www.linkedin.com/in/sophie-morreel-weber4b21886a/"
          >
            <div className="">{linkedInSVG}</div>
          </a>

          <div style={{ height: "0px" }}></div>
          <a href="https://g.co/kgs/Pob4AWo">
            <img width={"200"} src={google_ratings} loading="lazy" alt="" />
          </a>
          <div
            className="secondary"
            style={{ fontSize: "20px", fontWeight: 500 }}
          >
            18 avis
          </div>

          <div style={{ height: "4px" }}></div>
          <a href="#contact">
            <button
              id=""
              className="primary-button block cursor-pointer m-auto  
			  font-semibold py-2 px-6  rounded-md avenir"
            >
              {phoneSVG}
              Appeler
            </button>
          </a>
        </div>
      </div>
    </StyledHero>
  )
}
