import React from "react"
import styled from "styled-components"

import { mailSVG } from "../../assets/svg/SVGStorage"

const StyledLawyerCard = styled.div`
  .mygrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .verticalSpaceBetween {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
`

export default function LawyerCard({ lawyer, children }) {
  const { name, title, description, mail } = lawyer
  return (
    <StyledLawyerCard className="mygrid shadowed rounded md:grow md:basis-1">
      {children}

      <div className="text-center py-5 px-2">
        <div className="verticalSpaceBetween">
          <div>
            <div className="font-normal leading-6">{name}</div>
            <div className="tertiary">{title}</div>
            <p className="my-2 gray3 text-xs md:text-sm italic ">
              {description}
            </p>
          </div>
          <div>
            {mail && (
              <a href={`mailto:${mail}`}>
                <button className="primary-button block cursor-pointer m-auto font-semibold py-1.5 px-4  rounded-md avenir text-sm">
                  {mailSVG}
                  Contacter
                </button>
              </a>
            )}
          </div>
        </div>
      </div>
    </StyledLawyerCard>
  )
}
