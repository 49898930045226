import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
/* import { mailSVG } from "../../assets/svg/SVGStorage"
 */ import LawyerCard from "./LawyerCard"

const virginie_exterieur_URL = "../../images/virginie_exterieur.jpg"
const sophie_exterieur_URL = "../../images/sophie_exterieur.jpg"
// const julieV_exterieur_URL = "../../images/julieV_exterieur.jpg"
const julieL_URL = "../../images/julieL.jpg"
/*  const julieL_exterieur_URL = "../../images/julieL_exterieur.jpg"
 */
const StyledLawyers = styled.div`
  svg {
    width: 20px;
    fill: currentColor;
  }
  button {
    display: flex;
    gap: 0.5rem;
  }
  .mygrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .cardsGrid {
    display: grid;
    gap: 16px;
  }

  @media (min-width: 768px) {
    .cardsGrid {
      grid-template-columns: 1fr 1fr;
    }
  }
`

const sophie = {
  name: "Sophie Morreel Weber",
  title: "Avocate associée",
  description:
    "« Utiliser ce que la vie et l’université m’ont appris pour apporter de l’aide aux autres est l'essence de mon métier »",
  mail: "smorreelweber@lexcoavocats.com",
}
const virginie = {
  name: "Virginie Parisse",
  title: "Avocate associée",
  description:
    "« Pragmatique et à l’écoute, je vous propose un premier rendez vous déduit de vos honoraires si vous me confiez votre dossier »",
  mail: "vparisse@lexcoavocats.com",
}

/* "Pragmatique et à l’écoute, je sécurise les procédures" */

/* const julieV = {
  name: "Julie Vigneron",
  title: "Avocate collaboratrice",
  description:
    "« Compétente à l'International et en affaires, opiniatre, fiable et rapide, je suis votre conseil et votre défenseur »",
  mail: "julie.vigneron@lexcoavocats.com",
} */
const julieL = {
  name: "Julie Lepretre",
  title: "Juriste",
  description:
    "« Partagée entre la passion du droit et le besoin d'aider les autres, devenir avocate est d’évidence mon objectif »",
  mail: null,
}

export default function Lawyers() {
  return (
    <StyledLawyers>
      <div className="cardsGrid ">
        <LawyerCard lawyer={sophie}>
          <StaticImage
            className="h-100 rounded-l "
            width={250}
            alt=""
            quality={90}
            src={sophie_exterieur_URL}
          />
        </LawyerCard>

        <LawyerCard lawyer={virginie}>
          <StaticImage
            className="h-100 rounded-l "
            width={250}
            alt=""
            quality={90}
            src={virginie_exterieur_URL}
          />
        </LawyerCard>

        {/*    <LawyerCard lawyer={julieV}>
          <StaticImage
            className="h-100 rounded-l "
            width={250}
            alt=""
            quality={90}
            src={julieV_exterieur_URL}
          />
        </LawyerCard> */}
        <LawyerCard lawyer={julieL}>
          <StaticImage
            className="h-100 rounded-l "
            width={250}
            alt=""
            quality={90}
            src={julieL_URL}
          />
        </LawyerCard>
      </div>
    </StyledLawyers>
  )
}
