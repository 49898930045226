import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
const salle_reunion_URL = "../../images/salle_reunion.jpg"
const virginie_et_sophie_URL = "../../images/virginie_et_sophie.jpg"
const sophie_macbook_URL = "../../images/sophie_macbook.jpg"

const StyledPanorama = styled.div`
  .styledPanorama {
    display: flex;
    justify-content: center;
    background: #f5f3f3;
  }

  .styledPanorama img {
    border-radius: 2px;
  }

  @media (min-width: 768px) {
    .styledPanorama img {
      width: 300px;
    }
  }
`

export default function Panorama() {
  return (
    <StyledPanorama>
      <div className="styledPanorama">
        <div className="desktopOnly">
          <StaticImage
            width={500}
            alt=""
            quality={90}
            src={salle_reunion_URL}
          />
        </div>
        <div>
          <StaticImage
            width={500}
            alt="Sophie Morreel Weber et Virginie Parisse"
            quality={90}
            src={virginie_et_sophie_URL}
          />
        </div>

        <div className="desktopOnly">
          <StaticImage
            width={500}
            alt=""
            quality={90}
            src={sophie_macbook_URL}
          />
        </div>
      </div>
    </StyledPanorama>
  )
}
