import styled from "styled-components"

const CardContainer = styled.div`
  display: grid;
  gap: 20px;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export default CardContainer
